import React from 'react'
import Core from '../templates/Core'
import NameLogo from '../design_system/themeing/assets/primary-color-logo-withname.svg'
import styled from "styled-components";
import { IndexLink } from "../design_system/components/MenuLink"
import Helmet from 'react-helmet'
class BlogIndex extends React.Component {
  render() {
    const TopLogo = styled.div`
    padding-top:2em;
    margin: auto;
    text-align: center;
  `
    const IndexMenu = styled.div`
  display: flex;
  flex-direction: column;
  `
    const IndexLinkWrapper = styled.div`
    padding-top: 4em;
    align-items: center;
    margin: auto;
  `

    return (

      <Core>
        <Helmet>
          <title>Justin Isbell</title>
          <meta charSet="utf-8" />
          <meta name="description" content="This is the personal website of Justin Isbell, projects, resume, writing, and contact info"></meta>
          <link rel="canonical" href="https://www.justinisbell.org/" />
        </Helmet>
        <TopLogo>
          <img src={NameLogo} alt="Justin Isbell" />
        </TopLogo>
        <main>
          <IndexMenu>
            <IndexLinkWrapper>
              <IndexLink direction="/about">About</IndexLink>
            </IndexLinkWrapper>
            <IndexLinkWrapper>
              <IndexLink direction="/contact">Contact</IndexLink>
            </IndexLinkWrapper>
          </IndexMenu>
        </main>
      </Core>
    )
  }
}

export default BlogIndex
